import React, { useEffect } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { startOfMonth, endOfMonth } from 'date-fns'; // Import date-fns functions

const DatePicker = ({ label, visibleRange, setVisibleRange = () => {}, ...props }) => {


    // Effect to update the visible range when the selected date changes
    useEffect(() => {
      if (props.value) {
        const start = startOfMonth(props.value);
        const end = endOfMonth(props.value);
        setVisibleRange({ start, end });
      }
    }, [props.value]);

    if (
      typeof props.value === 'undefined' ||
      typeof props.onChange !== 'function'
    ) {
      console.error('DatePicker requires both value and onChange props.');
      return null;
    }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        ToolbarComponent={() => null}
        label={label}
        placeholder="dd/mm/yyyy"
        format="dd/MM/yyyy"
        inputVariant="filled"
        fullWidth
        variant="static"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;

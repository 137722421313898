import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box,} from '@material-ui/core';
import ClosedMenuLogo from 'assets/logo/closed-menu.png';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    spinningContainer: {
        // background: '#f00',
        position: 'absolute',
        left: '45%',
        top: '25%',
        width: '2em',
        height: '2em',
        backgroundSize: 'contain',
        display: 'block',
        margin: 'auto',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
    },
}));

export default function CircularIndeterminate({ styles = {}, centered = false, ...props }) {
    const classes = useStyles();

    return (
    <div className={classes.spinningContainer}>
        <style>
        {`
            /* spinner.css */
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            @keyframes scale {
                0%, 100% {
                    transform: rotate(0deg) scale(1);
                }
                50% {
                    transform: rotate(180deg) scale(1.1);
                }
            }

            .loading-logo {
                width: 100px;
                height: 100px;
                animation: scale 2s linear infinite;
            }
        `}
        </style>
        <Box className={centered ? classes.centered : null}>
            <div className={classes.root}>
                <div className="loading-container">
                    {/* Replace "logo.png" with the actual path to your company logo */}
                    <img src={ClosedMenuLogo} alt="Loading" className="loading-logo" />
                </div>
            </div>
        </Box>
    </div>
    );
}
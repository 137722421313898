import {
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
} from "@material-ui/core";

const SelectComponent = ({
  emptyValue = false,
  emptyLabel = "",
  onEmpty = () => {},
  id,
  value,
  values,
  label,
  labelId,
  style,
  onChange = () => {},
  hasError,
  errorMessage,
  disabled = false,
  ...props
}) => {
  return (
    <FormControl error={hasError} variant="outlined" style={style}>
      <InputLabel disabled={disabled} id={labelId}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        id={id}
        labelId={labelId}
        value={props.input?.value || value}
        onChange={(event) => {
          if (props.onFieldChange) {
            props.onFieldChange(event);
          }
          props.input?.onChange(event);
        }}
      >
        {emptyValue ? (
          <MenuItem
            key={0}
            value={null}
            style={style}
            onClick={(event) => {
              onEmpty();
            }}
          >
            <ListItemText primary={emptyLabel || ""} />
          </MenuItem>
        ) : null}
        {values.map((val, index) => {
          return (
            <MenuItem
              key={val.id}
              value={val.id}
              style={style || { color: 'black'} }
              onClick={(event) => onChange(val)}
            >
              <ListItemText primary={val.name || val.value} />
            </MenuItem>
          );
        })}
      </Select>
      {hasError ? (
        <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
export default SelectComponent;

import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';

const ContractSelect = ({ contracts, onChange, value }) => {
  const { t: translate } = useTranslation();

  return (
    <FormControl variant="outlined" style={{ width: '20rem' }}>
      {/* <InputLabel>{translate('contractSelect.contract')}</InputLabel> */}
      <Select
        label={translate('contractSelect.contract')}
        value={value}
        style={{
          width: '20rem',
          color: 'black',
        }}
        MenuProps={{
          getContentAnchorEl: () => null,
          PaperProps: {
            style: {
              maxHeight: '20rem',
              width: '20rem',
            },
          },
        }}>
        {contracts.map((contract, index) => {
          return (
            <MenuItem
              key={contract.id}
              value={contract.id}
              onClick={(event) => onChange(contract)}
              style={{ whiteSpace: 'break-spaces' }}>
              <ListItemText
                primary={contract.name}
                style={{ color: 'black', wordBreak: 'break-word' }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

ContractSelect.displayName = 'ContractSelect';

export default ContractSelect;

import jsonData from './payment_platform.json';
import Types from '../Types.mjs';

class PaymentPlatform extends Types {
    constructor(params = {}) {
        super({TYPES: jsonData});
        this.rates = {};
        Object.values(this.TYPES).forEach((value) => {
            this.rates[value.id] = value.rates;
        });
    }

    getRates(id) {
        return this.rates[id] || {};
    }
}
export default PaymentPlatform = new PaymentPlatform();

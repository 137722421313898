import React, { useContext, useState } from 'react';
import { HostService } from '../api/services/host';

const HostContext = React.createContext(null);

export const HostProvider = ({ children }) => {
    const [loading_, setLoading] = useState(false);
    const [code, setCode] = useState(null);
    const [data, setData] = useState(null);
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [total, setTotal] = useState(0);

    const getContracts = async () => {
        try {
          setLoading(true);
          const {contracts, total} = await HostService.getContracts();
    
          if (!contracts) {
            throw new Error();
          }
          // Home contracts expects you to set the state
          setContracts(contracts);
          setTotal(total||0);
          if(contracts && contracts[0]) {
            setContract(contracts[0]);
          }

          return contracts;
        } catch (err) {
          console.error(err);
          return false;
        } finally {
          setLoading(false);
        }
    };
  

    return (
        <HostContext.Provider
            value={{
                code,
                setCode,
                data, 
                setData,
                contract,
                contracts,
                setContract,
                getContracts,
            }}>
            {children}
        </HostContext.Provider>
    );
};

HostProvider.displayName = 'HostProvider';

export const useHost = () => useContext(HostContext);

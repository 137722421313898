import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import theme from './core/theme';
import Router from './core/routing';

import { AuthProvider } from './core/contexts/auth';
import { ExperiencerProvider } from './core/contexts/experiencer';
import { ContractProvider } from './core/contexts/contract';
import { ExperiencesProvider } from './core/contexts/experience';
import { ScheduleProvider } from './core/contexts/schedule';
import { BookingProvider } from './core/contexts/booking';
import { LocalizationProvider } from './core/contexts/localization';
import { HostProvider } from './core/contexts/host';
import { StripeProvider } from 'core/contexts/stripe';

import getDatepickerLocale from './core/locales/getDatepickerLocale';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getDatepickerLocale()}>
                <LocalizationProvider>
                    <AuthProvider>
                        <StripeProvider>
                            <ExperiencerProvider>
                                <HostProvider>
                                    <ContractProvider>
                                        <ExperiencesProvider>
                                            <ScheduleProvider>
                                                <BookingProvider>
                                                    <Router />
                                                </BookingProvider>
                                            </ScheduleProvider>
                                        </ExperiencesProvider>
                                    </ContractProvider>
                                </HostProvider>
                            </ExperiencerProvider>
                        </StripeProvider>
                    </AuthProvider>
                </LocalizationProvider>
                <ToastContainer />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

App.displayName = 'App';

export default App;

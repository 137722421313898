import React, { useEffect, useContext, useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { toast } from 'react-toastify';
import { useTranslation } from 'core/contexts/localization';

const StripeContext = React.createContext(null);

export const StripeProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const { t: translate } = useTranslation();

    // Make sure to call loadStripe outside of a component’s render to avoid
    // recreating the Stripe object on every render.
    // This is your test publishable API key.
    // const stripePromise = loadStripe('pk_test_pqax4QHNVtv98OWlyBjPZekH00Xsf5hzHP');
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

    return (
        <StripeContext.Provider
            value={{
                loading,
                stripePromise,
            }}>
            {children}
        </StripeContext.Provider>
    );
};

StripeProvider.displayName = 'StripeProvider';

export const useStripe = () => useContext(StripeContext);

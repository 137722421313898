import React, { useCallback, useState, useEffect } from 'react';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from '@stripe/react-stripe-js';

const CheckoutForm = ({ open = false, stripePromise, clientSecret }) => {
    
    const fetchClientSecret = useCallback(() => {
        return clientSecret
    }, []);

    const options = { fetchClientSecret };
    
    if (!open || !stripePromise || !options) {
        return null;
    }
    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};

export default CheckoutForm;

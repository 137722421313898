module.exports = {
    PATH: './logs/logs.txt',
    FLUSH: false,
    SUCCESS: 'success',
    ERROR: 'error',
	DB_ACTIONS: {
		CREATE: 'CREATE',
		ALTER: 'ALTER',
		UPDATE: 'UPDATE',
		DELETE: 'DELETE',
		DROP: 'DROP',
	},
    CONSTRAINTS: [
        'NOT NULL',
        'UNIQUE',
        'PRIMARY KEY',
        'FOREIGN KEY',
        'CHECK',
        'DEFAULT',
        'INDEX'
    ],
	NUMERICS: [
		'TINYINT',
		'BOOLEAN',
		'SMALLINT',
		'MEDIUMINT',
		'INT',
		'INTEGER',
		'BIGINT',
		'DECIMAL',
		'FLOAT',
		'DOUBLE',
		'BIT'
	],
    DATA_TYPES: [
        'TINYINT',
        'BOOLEAN',
        'SMALLINT',
        'MEDIUMINT',
        'INT',
        'INTEGER',
        'BIGINT',
        'DECIMAL',
        'FLOAT',
        'DOUBLE',
        'BIT',
        'DATE',
        'TIME',
        'DATETIME',
        'TIMESTAMP',
        'YEAR',
        'CHAR',
        'VARCHAR',
        'BINARY',
        'VARBINARY',
        'TINYBLOB',
        'BLOB',
        'MEDIUMBLOB',
        'LONGBLOB',
        'TINYTEXT',
        'TEXT',
        'MEDIUMTEXT',
        'LONGTEXT',
        'ENUM',
        'SET'
    ],
    INT: 'INT',
    DECIMAL: 'DECIMAL',
    VARCHAR: 'VARCHAR',
    DATETIME: 'DATETIME',
    NUMBER: 'number',
    STRING: 'string',
    BOOLEAN: 'BOOLEAN',
    BOOLEANJS: 'boolean',
    UNDEFINED: 'undefined',
    OBJECT: 'object',
    COTRIPER_TYPES: {
        TRIPER: 'triper',
        GROUP: 'group'
    },
    ERRORS: {
        UNKNOWN: {
            code: 0,
            msg: 'Unkown error.'
        },
        EMAIL_USED: {
            code: 1,
            msg: 'User Email already in use.'
        },
        INVALID_DATE: {
            code: 2,
            msg: 'Date received is not a valid date.'
        },
        INVALID_EMAIL: {
            code: 3,
            msg: 'Invalid email received.'
        },
        INVALID_PASSWORD: {
            code: 4,
            msg: 'Invalid password received.'
        },
        INVALID_SESSION: {
            code: 5,
            msg: 'Invalid session.'
        },
        NO_RECORD: {
            code: 6,
            msg: 'No record found.'
        },
        INVALID_TRIPER: {
            code: 7,
            msg: 'Invalid triper id.'
        },
        TRIPER_NOT_ACTIVE: {
            code: 8,
            msg: 'Triper profile is not activated.'
        },
        INVALID_COTRIPER: {
            code: 9,
            msg: 'Invalid CoTriper.'
        },
        INVALID_TRIP: {
            code: 10,
            msg: 'Invalid Trip ID.'
        },
        INVALID_TRIPER_TRIP: {
            code: 11,
            msg: 'Triper does not belong to this Trip.'
        },
        INVALID_NAME: {
            code: 12,
            msg: 'Invalid Name.'
        },
        INVALID_EXPERIENCER: {
            code: 13,
            msg: 'Invalid Experiencer Id.'
        },
        INVALID_EXPERIENCE: {
            code: 14,
            msg: 'Invalid Experience.'
        },
        NO_AVAILABILITY: {
            code: 15, 
            msg: 'No availability for that date.'
        },
        INVALID_STATUS: {
            code: 16, 
            msg: 'Invalid status.'
        },
        INVALID_REASON: {
            code: 17, 
            msg: 'Invalid cancel reason.'
        },
        INVALID_LOCATION: {
            code: 18, 
            msg: 'There is an invalid location for that date.'
        },
        INVALID_TRIP_EXPERIENCE: {
            code: 19, 
            msg: 'Invalid trip experience.'
        },
        DUPLICATE_TRIPER_TRIP: {
            code: 20,
            msg: 'Triper already belong to this Trip.'
        },
        NO_PAYMENT_ID: {
            code: 21,
            msg: "No payment_id found in query parameters."
        },
        NO_ORDER_ID: {
            code: 22,
            msg: "No order_id or preference_id found in query parameters."
        },
        NO_PAYMENT_PLATFORM: {
            code: 23,
            msg: "No payment_platform found in query parameters."
        },
        NO_PAYMENT_STATUS: {
            code: 24,
            msg: "No payment status found in query parameters."
        },
        INVALID_PAYMENT_PLATFORM:{
            code: 25,
            msg: "Invalid payment platform."
        },
        INVALID_PAYMENT_STATUS: {
            code: 26,
            msg: "Invalid payment status."
        },
        PAYMENT_ALREADY_EXECUTED: {
            code: 27,
            msg: "The payment has already been executed."
        },
        PAYPAL_PAYMENT_WENT_WRONG: {
            code: 28,
            msg: "Ups! Something went wrong with the process of the PayPal payment."
        },
        CANCELED_BOOKING: {
            code: 29,
            msg: "Booking is already cancelled."
        },
        MERCADOPAGO_PAYMENT_WENT_WRONG: {
            code: 30,
            msg: "Ups! Something went wrong with the process of the MercadoPago payment."
        },
        PAYMENT_NOT_FOUND: {
            code: 31,
            msg: "Payment id not found"
        },
        INVALID_EXPERIENCER_PHONE: {
            code: 32,
            msg: 'Invalid Experiencer Phone Number.'
        },
        INVALID_HOST: {
            code: 33,
            msg: 'Invalid Host Id.'
        },
        INVALID_PARAM: {
            code: 34,
            msg: 'Invalid Param.'
        },
    },
    ENVIRONMENTS: {
        PRODUCTION: "production",
        DEVELOPMENT: "development",
        BETA: "beta"
    },
    EXPERIENCE_DATA_TYPE: {
        ENVIRONMENTAL_POLICIES: 1,
        CANCELLATION_POLICIES: 2,
        INCLUDES_POLICIES: 3,
        PAYMENT_METHODS: 4,
        ANTICIPATION: 5
    },
    LOG: {
        TYPE: {
            DEBUG: "DEBUG",
            INFO: "INFO",
            WARNING: "WARNING",
            ERROR: "ERROR",
        },
        CATEGORY: {
            GENERAL: "GENERAL",
            SQL: "SQL"
        }
    }
};

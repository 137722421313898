
const TIME_ZONES = Intl.supportedValuesOf('timeZone').map((tz) => {
  return { id: tz, name: TimeZoneFormattedUtils(tz) };
});
function DateFormattedUtils(date, language, options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: 'UTC',
},
translate= (e,text) => text ) {
  if(!date) { return translate('libs.date.noDate', 'No Date'); }

  if (!language) {
    language = 'es-ES';
  }

  const dateFormatted = new Date(date).toLocaleDateString(language, options);
  return dateFormatted;
}
//---------------------------------

//---------------------------------
// Formats a date object or string to a localized time string based on given language and options.
function TimeFormattedUtils (date, language, options = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: 'UTC',
},
translate= (e,text) => text ) {
  if(!date) { return translate('libs.date.noDate', 'No Date'); }

  if (!language) {
    language = 'es-ES';
  }

  const dateFormatted = new Date(date).toLocaleTimeString(language, options);
  return dateFormatted;
}
//---------------------------------

//---------------------------------
// Converts a duration in minutes to a readable string showing days, hours, and minutes.
function TimeDurationFormatter(minutes, translate) {
  const days = parseInt(minutes / (60 * 24));
  const hours = parseInt((minutes % (60 * 24)) / 60);
  const minutesRes = parseInt(minutes % 60);

  let result = '';

  if (days > 0) {
    result += `${days}  ${days === 1 ? translate('libs.time.day') : translate('libs.time.days')}} `;
  }

  if (hours > 0) {
    result += `${hours} ${hours === 1 ?  translate('libs.time.hour') :  translate('libs.time.hours')} `;
  }

  if (minutesRes > 0) {
    result += `${minutesRes}  ${minutesRes === 1 ?  translate('libs.time.minute'): translate('libs.time.minutes')}`;
  }
  return result;
}
//---------------------------------

//---------------------------------
// Parses a time string formatted as "HH:mm" and returns it without seconds or additional formatting.
const TimeFormattedMinutesHours = (timeString) => {
  if(!timeString){
    return ''
  }
  const [hours, minutes] = timeString.split(":");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
};
//---------------------------------

//---------------------------------
// Compares two dates and returns true if they are on the same day, ignoring time beyond the day.
function compareDates(day, slotDate) {
  const dayDate = day.setHours(0, 0, 0, 0);
  const slotDay = slotDate.setHours(0, 0, 0, 0);
  return dayDate === slotDay;
}
//---------------------------------

//---------------------------------
// Converts a date object or string to a string in the format "YYYY-MM-DD".
function formatDateToYYYYMMDD(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() es base 0; enero = 0
  const day = date.getDate().toString().padStart(2, '0'); // getDate() es base 1

  return `${year}-${month}-${day}`;
}
/* @param {string} loc - IANA representative location
 * @param {Date} date - default to current date
 * @returns {string} offset as ±H[mm]
 */
function getOffsetForLoc(loc, date = new Date()) {
  // Use Intl.DateTimeFormat to get offset
  let opts = {hour: 'numeric', timeZone: loc, timeZoneName:'short'};
  let getOffset = lang =>  new Intl.DateTimeFormat(lang, opts)
    .formatToParts(date)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {}).timeZoneName;
  let offset = getOffset('en');
  // If offset is an abbreviation, change language
  if (!/^UCT|GMT/.test(offset)) {
    offset = getOffset('fr');
  }
  // Remove GMT/UTC 
  return offset.substring(3);
}
function TimeZoneFormattedUtils(tz) { return `${tz} (GMT${getOffsetForLoc(tz)})`; }

export {
  TIME_ZONES,
  DateFormattedUtils,
  TimeDurationFormatter,
  TimeFormattedMinutesHours,
  compareDates,
  formatDateToYYYYMMDD,
  TimeFormattedUtils,
  getOffsetForLoc,
  TimeZoneFormattedUtils,
};
import React, { useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import DatePicker from '../../../Input/DatePicker';
import Slots from '../Slots';
import {
  compareDates,
  formatDateToYYYYMMDD,
} from '../../../../utils/dateTimeFormatted';
import { useStyles } from './styles';


const ExperienceDatePicker = ({
  showPrice = false,
  showAvailability = false,
  slots = [],
  quantity,
  datePicker, 
  experience,
  setSelectedSlot,
  setDatePicker,
  setVisibleRange,
  ...props
}) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDate] = useState('');


  const renderDayContent = (
    day,
    selectedDate,
    isInCurrentMonth,
    dayComponent
  ) => {

    const dayFormatted = formatDateToYYYYMMDD(day);
    // const selectedDayFormatted = formatDateToYYYYMMDD(selectedDate);

    // const slotsForDay = slots.find(slot => slot.date === dayFormatted);
    const slotsForDay = slots.filter(slot => slot.start?.substring(0,10) === dayFormatted);

    const dateToFo = formatDateToYYYYMMDD(selectedDate);
    setSelectedDate(dateToFo);
    
    let price = false;
    let availability = false;
    if(slotsForDay.length) {
        for (let i = 0; i < slotsForDay.length; i++) {
          const slot = slotsForDay[i];
          if(slot.available === true) {
            availability = true;
          }
          if(slot.price && (!price || (parseFloat(slot.price) > price))) {
            price = parseFloat(slot.price);
          } 
        }
    }

    // setSlotSelected(() => {
    //   return slots.filter((slot) => selectedDayFormatted === slot.start?.substring(0,10));
    // });

    let borderColor = classes.defaultBorderColor;
    if (availability) {
      borderColor = classes.backgroundGreen;
    }
    const isDaySelected = compareDates(day, selectedDate);

    const customDay = React.cloneElement(dayComponent, {
      ...dayComponent.props,
      children: (
        <Grid className={isDaySelected ? classes.selectedDay : borderColor}>
          <Grid className={classes.selectedDaySize}>
            {dayComponent.props.children}
          </Grid>
          {showPrice ? (
            <Typography className={classes.customPrice}>
              {price ? `$${price}` : '\u00A0'}
            </Typography>
          ) : (
            <Typography className={classes.customPrice}>&nbsp;</Typography>
          )}
        </Grid>
      ),
    });
    return customDay;
  };

  return (
    <Grid className={classes.gridPadre}>
      <Box className={classes.gridDataPicker}>
          {/* The DatePicker component breaks if it doesn't receive the 'value' and 'onChange' props. 
             This is because it strictly requires these props for managing its internal state and updating 
             the selected date. Always ensure these props are passed to avoid errors */}
        <DatePicker
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
            renderDayContent(day, selectedDate, isInCurrentMonth, dayComponent)
          }
          value={datePicker}
          onChange={setDatePicker}
          onMonthChange={setDatePicker}
          setVisibleRange={setVisibleRange}
          {...props}
        />
      </Box>
      <Box className={classes.slotsContainer}>
        <Slots
          experience={experience}
          quantity={quantity}
          slot={slots}
          daySelected={selectedDay}
          setSelectedSlot={setSelectedSlot}
        />
      </Box>
    </Grid>
  );
};

export default ExperienceDatePicker;

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ROUTES } from './routes';
import RedirectRoute from './redirectRoute';

import LandingPage from 'modules/Landing';
import HomePage from 'modules/Home';
import ExperiencerPage from 'modules/Experiencer';
import BookingsPage from 'modules/Bookings';
import BookingPage from 'modules/Bookings/components/Booking';
import BookingNewPage from 'modules/Bookings/components/BookingNew';
import CheckoutValidation from "modules/Bookings/components/BookingNew/components/PaymentValidation";
// import AddBooking from 'modules/Bookings/components/GlobalAddBooking';
import ForgottenPassword from 'modules/ForgottenPassword';
import VerifyCode from 'modules/ForgottenPassword/Verify';
import ResetPassword from 'modules/ForgottenPassword/Reset';
// import ViajeroBooking from 'modules/Calendar';
// import ViajeroCheckout from 'modules/Checkout';
// import ViajeroSavedExperiences from 'modules/SavedExperiences';
// import HostelTemplate from "modules/Landing/Hostel";
// import PurchaseTemplate from "modules/Purchase";
// import ErrorTemplate from 'modules/Errors';
// import PendingTemplate from 'modules/Pending';

const Router = () => (
  <BrowserRouter>
      <Switch>
        <Route path={ROUTES.LANDING} exact>
          <LandingPage />
        </Route>
        
      <RedirectRoute path={ROUTES.HOME} component={HomePage} />
      <RedirectRoute path={ROUTES.HOST} component={ExperiencerPage} exact />
      {/* <RedirectRoute path={ROUTES.BOOKINGS_ADD} component={AddBooking} /> */}
      <RedirectRoute path={ROUTES.BOOKINGS_NEW_CODE} component={BookingNewPage} />
      <RedirectRoute path={ROUTES.BOOKINGS_NEW_ID} component={BookingNewPage} />
      <RedirectRoute path={ROUTES.BOOKINGS_NEW} component={BookingNewPage} />
      <RedirectRoute path={ROUTES.BOOKINGS_ID} component={BookingPage} />
      <RedirectRoute path={ROUTES.BOOKINGS} component={BookingsPage} exact />
      <RedirectRoute path={ROUTES.CHECKOUT_VALIDATION} component={CheckoutValidation} exact />
      

      <RedirectRoute path={ROUTES.FORGOTTEN_PASSWORD.VERIFY} exact>
        <VerifyCode />
      </RedirectRoute>

      <RedirectRoute path={ROUTES.FORGOTTEN_PASSWORD.RESET} exact>
        <ResetPassword />
      </RedirectRoute>

      <RedirectRoute path={ROUTES.FORGOTTEN_PASSWORD.SEND}>
        <ForgottenPassword />
      </RedirectRoute>
      
      {/* <Route path={ROUTES.LANDINGHOSTEL} exact>
        <LandingHostel />
      </Route> */}

        {/* <Route path={ROUTES.BOOKING_CODE}>
          <ViajeroBooking />
        </Route>

        <Route path={ROUTES.BOOKING}>
          <ViajeroBooking />
        </Route>

        <Route path={ROUTES.CHECKOUT_VALIDATION} exact>
          <CheckoutValidation />
        </Route>

        <Route path={ROUTES.CHECKOUT}>
          <ViajeroCheckout />
        </Route>

        <Route path={ROUTES.SAVEDEXPERIENCES}>
          <ViajeroSavedExperiences />
        </Route>

        <Route path={ROUTES.QR}>
          <HostelTemplate />
        </Route>

        <Route path={ROUTES.PURCHASE}>
          <PurchaseTemplate goBackUrl="" />
        </Route>

        <Route path={ROUTES.ERROR}>
          <ErrorTemplate goBackUrl="" />
        </Route>

        <Route path={ROUTES.PENDING}>
          <PendingTemplate goBackUrl="" />
        </Route> */}

      </Switch>
  </BrowserRouter>
);

Router.displayName = 'Router';

export default Router;

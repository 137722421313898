export const parseHostToModel = (host) => ({
    id: host?.id,
    name: host?.name,
    status: host?.status,
    pictureURL: host?.pictureURL,
    addressStreet: host?.addressStreet,
    addressStreetAlt: host?.addressStreetAlt,
    addressCity: host?.addressCity,
    addressState: host?.addressState,
    addressCountryId: host?.addressCountryId,
    addressPC: host?.addressPC,
    other: host?.other,
    officePhone: host?.officePhone,
    mobilePhone: host?.mobilePhone,
    email: host?.email,
    emailAlt: host?.emailAlt,
    emergencyDetails: host?.emergencyDetails,
    emergencyContact: host?.emergencyContact,
    billingDetails: host?.billingDetails,
    companySize: host?.companySize,
    addressCountry: host?.addressCountry,
    description: host?.description,
    payByCash: host?.payByCash,
    payByTerminal: host?.payByTerminal,
    payByLink: host?.payByLink,
    acceptedPaymentPlatforms: host?.acceptedPaymentPlatforms,
  });
  
class Types  {

    constructor({TYPES}) {
        this.TYPES =  TYPES;
        this.types = {};
        this.IDS = {};
        Object.values(this.TYPES).forEach(value => {
            this.types[value.id] = value.xlat;
            this.IDS[value.name] = value.id;
        });
    }
    
    getIdsFromNumber = (number) => {
        const binaryString = number.toString(2);
        const binaryArray = binaryString.split("");
        const binaryArrayReversed = binaryArray.reverse();
        const ids = this.getIds();
        const idsArray = [];
        binaryArrayReversed.forEach((bit, index) => {
            if (bit === "1") {
                idsArray.push(ids[index]);
            }
        });
        return idsArray;
    };

    getIds = () => [...Object.values(this.TYPES).map(types => types.id)];
    getTranslatedName = (types, id, language) => {
        let lang = language.substring(0,2);
        if (lang !== 'es' && lang!== 'en') { lang = 'en'; }
        return [types[id][lang].name]
    };

    getTranslatedNameFromNumber = (types, number, language) => {
        const ids = this.getIdsFromNumber(number);
        const names = ids.map(id => this.getTranslatedName(types, id, language));
        return names;
    };

    isValidId = (id) => {
        const ids = this.getIds();
        for(let i = 0; i < ids.length; i++) {
            if(Number.parseInt(id) === Number.parseInt(ids[i])) { return true; }
        }
        return false;
    };

    getId = (name) => {
        return this.IDS[name];
    };

    getLabel = (id) => {
        for (const tag in this.IDS) {
            if (parseInt(id) === this.IDS[tag]) { return tag; }
        }
    };

    getBitmapTypes(num = 0) {
        const ids = this.getIdsFromNumber(num); // [2,6]
        const idMap = ids.map(id => {
            return {
                id
            };
        });
        return idMap;
    };

    setBitmapTypes(types) {
        let number = 0;
        const ids = this.getIds(); // [1,2,3,4,5,6]

        types.forEach(type => {
            const typeIndex = ids.findIndex(id => id == type.id);
            number += Math.pow(2, typeIndex);
        });
        return number;
    };
};

export default Types;
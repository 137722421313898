import { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
    useMediaQuery,
    Grid,
    Select,
    MenuItem,
    List,
    Divider,
    ListItem,
    ListItemText,
    Button,
    ButtonGroup,
    Paper,
    Hidden,
} from '@material-ui/core';

import BookingOrigin from '../../../types/BookingOrigin/BookingOrigin'

import ExperienceAvailabilityPageComponent from '../ExperienceAvailabilityPage';
import Dialog from '../Dialog';
import Skeleton from '../Skeleton';
import Title from '../Title';
import BasicTable from '../Table';
import PaginationControlled from '../Pagination';
import CircularProgress from '../Progress';

import ProfiletIcon from '../../assets/icons/general/profile.svg';

import {
    DateFormattedUtils,
    TimeFormattedUtils,
    formatDateToYYYYMMDD,
} from '../../utils/dateTimeFormatted';
import { MoneyFormattedUtils } from '../../utils/moneyFormatted';

import BookingStatus from '../../../status/BookingStatus/BookingStatus';

import { useStyles } from './styles';

function groupByCategory(resources = []) {
    const grouped = [];

    resources.forEach((resource) => {
        resource.categories.forEach((category) => {
            const foundCategory = grouped.find(
                (cat) => cat.name === category.name
            );
            if (foundCategory) {
                foundCategory.resources.push(resource);
            } else {
                grouped.push({
                    name: category.name,
                    id: category.id,
                    resources: [resource],
                });
            }
        });
    });

    return grouped;
}

function groupResourcesByCategory(
    categories,
    selectedCategoriesArray,
    setFirstTime
) {
    const groupedResources = [];
    if (!selectedCategoriesArray) return;
    selectedCategoriesArray.forEach((categoryObj) => {
        const categoryId = Object.keys(categoryObj)[0];
        const selectedIds = categoryObj[categoryId];
        const category = categories.find(
            (cat) => cat.categoryId === parseInt(categoryId)
        );

        if (category) {
            const categoryResources = category.resources.filter((resource) =>
                selectedIds.includes(resource.resourceId)
            );

            if (categoryResources.length > 0) {
                groupedResources.push({
                    id: category.categoryId,
                    name: category.categoryName,
                    resources: categoryResources.map((res) => ({
                        id: res.resourceId,
                        name: res.resourceName,
                    })),
                });
            }
        }
    });

    setFirstTime(false);
    return groupedResources;
}

const BookingPageComponent = ({
    booking,
    setBooking,
    payment,
    bookingLogs,
    bookingLogsOptions,
    resources,
    onSave = () => {},
    onCancel = () => {},
    showTriper = false,
    showPayment = false,
    showLogs = false,
    canEdit = false,
    hasChanged = false,
    setHasChanged = () => {},
    resourceSelected,
    setResourceSelected,
    accionButtons = {},
    getAvailability = () => {},
    translate = (t, def) => {
        return def || t;
    },
    ...props
}) => {
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const [openCancelReason, setOpenCancelReason] = useState(false);
    const [openAvailability, setOpenAvailability] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resourcesFormatted, setResourcesFormatted] = useState([]);
    const [selectedExperienceItem, setSelectedExperienceItem] = useState(null);
    const [slots, setSlots] = useState();
    const [datePicker, setDatePicker] = useState(new Date());
    const [isFirstTime, setFirstTime] = useState(true);
    const [visibleRange, setVisibleRange] = useState({ start: null, end: null });

    useEffect(() => {
        if (isFirstTime) {
            setResourcesFormatted(groupByCategory(booking.resources));
        }
    }, [booking.resources]);

    useEffect(() => {
        if(openAvailability && Date.parse(visibleRange.start) && Date.parse(visibleRange.end)) {
            setLoading(true);
            if (!booking) {
                return;
            }
            if (Array.isArray(booking) && booking.length === 0) {
                return;
            }
            if (!booking.experienceDetailId) {
                return;
            }
            const exp = {
                id: booking.experienceId,
                experienceDetailId: booking.experienceDetailId,
            };
            setSelectedExperienceItem(exp);
            getAvailability({
                expId: booking?.experienceId,
                fromDateTime: new Date(visibleRange.start),
                toDateTime: new Date(visibleRange.end),
                groupSize: booking?.quantity,
                code: booking?.contractId,
            }).then((res) => {
                setSlots([...res]);
                setLoading(false);
            });
        }
    }, [openAvailability, visibleRange]);

    const setTimeOfArrival = (date, timezone) => {
        if (date) {
            setResourcesFormatted(
                groupResourcesByCategory(resources, resourceSelected)
            );
            setBooking({ ...booking, date, dateUTC: date, timezone, resources: resourceSelected });
            setHasChanged(true);
            setOpenAvailability(false);
        }
    };

    const handleCloseCancelReason = () => {
        setOpenCancelReason(false);
    };

    const handleCloseSetDate = () => {
        setOpenAvailability(false);
    };

    // const handleDatePickerChange = (date) => {
    //     setDateDatePicker(date);
    //     setTimeOfArrival(null);
    // };

    // const handleMonthChange = (date) => {
    //     setScheduleDate(formatDateToYYYYMMDD(date));
    // };

    const renderStatus = () => {
        const status = parseInt(booking.status);
        let colorLabel =
            status === 4 ? '#A6ba62' : status === 2 ? '#9bc2d1' : '#E39348 ';
        return (
            <span style={{ color: colorLabel }}>
                {' '}
                {BookingStatus.getLabel(booking.status)}{' '}
            </span>
        );
    };
  
    const columns = [
        {
            label: translate('bookingForm.triperBooking.firstname'),
            val: 'name',
        },
        {
            label: translate('bookingForm.triperBooking.lastname'),
            val: 'lastName',
        },
        {
            label: 'Email',
            val: 'email',
        },
        {
            label: translate('bookingForm.triperBooking.dob'),
            val: 'dob',
        },
        {
            label: translate('bookingForm.triperBooking.nationality'),
            val: 'nationality',
        },
        {
            label: translate('bookingForm.triperBooking.personalId'),
            val: 'passport',
        },
    ];

    const prettifyTripers = (tripers = []) => {
        return tripers.map((bookingData) => {
            return {
                ...tripers,
                name: bookingData.firstName,
                lastName: bookingData.lastName,
                email: bookingData.email,
                dob: bookingData.dob.substring(0, 10),
                nationality: bookingData.nationality,
                passport: bookingData.personalId,
            };
        });
    };

    const renderCancelReason = () => {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Title size={'xl'} value={'Cancel reason'} />
                        <textarea
                            style={{ marginBottom: '2em' }}
                            onChange={(e) => {
                                setBooking({
                                    ...booking,
                                    cancelReason: e.target.value,
                                });
                                setHasChanged(true);
                            }}
                            id="cancelReason"
                            name="cancelReason"
                            component={'textarea'}
                            label={'Cancel Reason'}
                            disabled={!canEdit}
                            multiline
                            rows={6}></textarea>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Title
                        value={
                            <>
                                Booking code: {booking?.code} {renderStatus()}
                            </>
                        }
                    />
                </Grid>
                <Grid>
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="primary">
                        {accionButtons?.setDate?.show ? (
                            <Button
                                onClick={() => {
                                    setOpenAvailability(true);
                                }}>
                                CHANGE DATE
                            </Button>
                        ) : (
                            ''
                        )}
                        {accionButtons?.setNoShow?.show ? (
                            <Button
                                onClick={() => {
                                    setBooking({
                                        ...booking,
                                        status: BookingStatus.getId('noshow'),
                                    });
                                    setHasChanged(true);
                                }}>
                                No Show
                            </Button>
                        ) : (
                            ''
                        )}
                        {accionButtons?.setCancel?.show ? (
                            <Button
                                onClick={() => {
                                    setBooking({
                                        ...booking,
                                        status: BookingStatus.getId(
                                            'cancelled'
                                        ),
                                    });
                                    setOpenCancelReason(true);
                                    setHasChanged(true);
                                }}>
                                Cancel
                            </Button>
                        ) : (
                            ''
                        )}
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Form
                onSubmit={onSave}
                initialValues={booking}
                render={({ handleSubmit, form }) => (
                    <form>
                        {canEdit && hasChanged ? (
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                alignContent="flex-end"
                                style={{ padding: '25px' }}>
                                <Grid
                                    item
                                    container
                                    justifyContent={'space-between'}
                                    style={{ width: 'auto' }}>
                                    <Button
                                        style={{
                                            marginRight: '12px',
                                            height: '40px',
                                            width: '100px',
                                        }}
                                        disabled={!canEdit}
                                        type="button"
                                        onClick={() => {
                                            onCancel();
                                            setHasChanged(false);
                                        }}>
                                        CANCEL
                                        {/* {translate('btn_cancel')} */}
                                    </Button>

                                    <Button
                                        onClick={(e) => {
                                            handleSubmit(e);
                                            setHasChanged(false);
                                        }}
                                        style={{
                                            height: '40px',
                                            width: '100px',
                                            backgroundColor: '#4CAF50',
                                            color: '#fff',
                                        }}
                                        disabled={!canEdit}
                                        type="submit">
                                        SAVE
                                        {/* {translate('btn_submit')} */}
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                        <Grid
                            container
                            alignContent="center"
                            justifyContent="center">
                            <Grid
                                container
                                className={[
                                    classes.previewContainer,
                                    classes.widget,
                                ]}>
                                {/* <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid> */}
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    item
                                    className={classes.previewLeft}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}>
                                    <p>Exp: {booking.experienceName}</p>
                                    <p>
                                        Date:{' '}
                                        {DateFormattedUtils(
                                            booking.dateUTC,
                                            null,
                                            {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                timeZone: booking.timezone || 'UTC',
                                            }
                                        )}
                                    </p>
                                    <p>
                                        Time:{' '}
                                        {TimeFormattedUtils(
                                            booking.dateUTC,
                                            null,
                                            {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true,
                                                timeZone: booking.timezone || 'UTC',
                                            }
                                        )}
                                    </p>
                                    <p>
                                        Location: {booking.experienceLocation}
                                    </p>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems={
                                        isSmall ? 'flex-start' : 'flex-end'
                                    }
                                    item
                                    className={classes.previewRight}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}>
                                    <p>
                                        {booking.firstName} {booking.lastName}
                                    </p>
                                    <p>{booking.email}</p>
                                    <p>{booking.mobilePhone}</p>
                                    <p>
                                        {booking.quantity} adults
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>

                        {showTriper && booking?.tripers?.length ? (
                            <BasicTable
                                columns={columns}
                                values={prettifyTripers(booking.tripers)}
                            />
                        ) : (
                            ''
                        )}

                        <Grid container justifyContent="center">
                            <Grid
                                container
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className={classes.widget}>
                                {/* // Title */}
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}>
                                    <Title size={'l'} value="Booking Details" />
                                </Grid>
                                {/* // Details */}
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Created on'} />
                                    <p>
                                        {DateFormattedUtils(
                                            booking.createdOn,
                                            null,
                                            {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                timeZone: booking.timezone || 'UTC',
                                            }
                                        )}
                                    </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {booking.updatedOn ? (
                                        <>
                                            <Title
                                                size={'m'}
                                                value={'Updated on'}
                                            />
                                            <p>
                                                {DateFormattedUtils(
                                                    booking.updatedOn,
                                                    null,
                                                    {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        timeZone: booking.timezone || 'UTC',
                                                    }
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Origin'} />
                                    <p>{BookingOrigin.getLabel(booking.originId)}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {booking.contractName ? (
                                        <>
                                            <Title
                                                size={'m'}
                                                value={'Contract'}
                                            />
                                            <p>{booking.contractName}</p>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {booking.host ? (
                                        <>
                                            <Title size={'m'} value={'Host'} />
                                            <p>{booking.host}</p>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {booking.hostName ? (
                                        <>
                                            <Title
                                                size={'m'}
                                                value={'Host Agent'}
                                            />
                                            <p>{booking.hostName}</p>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Total Price'} />
                                    <p>
                                        {booking.currency}{' '}
                                        {MoneyFormattedUtils(booking.price)}
                                    </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Paid Price'} />
                                    <p>
                                        {booking.currency}{' '}
                                        {MoneyFormattedUtils(booking.paidPrice)}
                                    </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title size={'m'} value={'Status'} />
                                    <p>
                                        {BookingStatus.getLabel(booking.status)}
                                    </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Title
                                        size={'m'}
                                        value={'To Be Paid Price'}
                                    />
                                    <p>
                                        {booking.currency}{' '}
                                        {MoneyFormattedUtils(
                                            parseFloat(booking.price) -
                                                parseFloat(booking.paidPrice)
                                        )}
                                    </p>
                                </Grid>
                                {parseInt(booking.status) ===
                                BookingStatus.STATUS.CANCELLED.id ? (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}>
                                            <Title
                                                size={'m'}
                                                value={'Cancel reason'}
                                            />
                                            <Field
                                                style={{
                                                    marginBottom: '2em',
                                                    width: '90%',
                                                }}
                                                onChange={(e) => {
                                                    setBooking({
                                                        ...booking,
                                                        cancelReason:
                                                            e.target.value,
                                                    });
                                                    setHasChanged(true);
                                                }}
                                                id="cancelReason"
                                                name="cancelReason"
                                                component={'textarea'}
                                                label={'Cancel Reason'}
                                                disabled={!canEdit}
                                                multiline
                                                rows={6}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    ''
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}>
                                    <Title
                                        size={'m'}
                                        value={'Additional Comments'}
                                    />
                                    {/* <textarea style={{ width: '90%', minHeight: '100px', minWidth: '200px' }}>{booking.additional}</textarea> */}
                                    <Field
                                        style={{
                                            marginBottom: '2em',
                                            width: '90%',
                                        }}
                                        onChange={(e) => {
                                            setBooking({
                                                ...booking,
                                                additional: e.target.value,
                                            });
                                            setHasChanged(true);
                                        }}
                                        id="additional"
                                        name="additional"
                                        component={'textarea'}
                                        label={'Additional Comments'}
                                        disabled={!canEdit}
                                        multiline
                                        rows={6}
                                    />
                                </Grid>
                            </Grid>
                            {resourcesFormatted?.length > 0 ? (
                                <Grid
                                    Container
                                    style={{
                                        boxShadow:
                                            '0 4px 8px rgba(0, 0, 0, 0.6)',
                                        margin: 10,
                                        padding: '1rem',
                                        paddingLeft: '5rem',
                                        paddingRight: '5rem',
                                        borderRadius: '1rem',
                                    }}>
                                    <Title
                                        size={'l'}
                                        value="Resources Details"
                                    />
                                    {resourcesFormatted.map(
                                        (category, index) => (
                                            <Grid key={index}>
                                                <h3>{category.name}</h3>
                                                <List component="nav">
                                                    {category.resources.map(
                                                        (resource) => (
                                                            <ListItem
                                                                key={
                                                                    resource.id
                                                                }
                                                                divider>
                                                                <ListItemText
                                                                    style={{
                                                                        color: '#000',
                                                                    }}
                                                                    primary={
                                                                        resource.name
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )
                                                    )}
                                                </List>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            ) : (
                                ''
                            )}
                            {showPayment && parseFloat(booking.price) > 0 ? (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}>
                                    <Grid
                                        container
                                        className={[
                                            classes.paymentContainer,
                                            classes.widget,
                                        ]}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}>
                                            <Title
                                                size={'l'}
                                                value="Payment Details"
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}>
                                                <Title
                                                    size={'m'}
                                                    value={'uTriper Comission'}
                                                />
                                                <p>
                                                    {booking.currency}{' '}
                                                    {MoneyFormattedUtils(
                                                        parseFloat(
                                                            booking.commission
                                                        )
                                                    )}
                                                </p>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}>
                                                <Title
                                                    size={'m'}
                                                    value={'Discounts'}
                                                />
                                                <p>
                                                    {booking.discountRate ||
                                                        'N/A'}
                                                </p>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}>
                                                <Title
                                                    size={'m'}
                                                    value={'Fee Rate'}
                                                />
                                                <p>{booking.feeRate}%</p>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}>
                                                <Title
                                                    size={'m'}
                                                    value={'Experience Pay Now'}
                                                />
                                                <p>{booking.payNow}%</p>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}>
                                            {payment ? (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}>
                                                        <Title
                                                            size={'m'}
                                                            value={'Created on'}
                                                        />
                                                        <p>
                                                            {DateFormattedUtils(
                                                                payment.createdOn
                                                            )}
                                                        </p>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}>
                                                        <Title
                                                            size={'m'}
                                                            value={'Updated on'}
                                                        />
                                                        <p>
                                                            {DateFormattedUtils(
                                                                payment.updatedOn
                                                            )}
                                                        </p>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}>
                                                        <Title
                                                            size={'m'}
                                                            value={
                                                                'Payment Platform'
                                                            }
                                                        />
                                                        <p>
                                                            {
                                                                payment.paymentPlatformName
                                                            }
                                                        </p>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}>
                                                        <Title
                                                            size={'m'}
                                                            value={
                                                                'Payment Status'
                                                            }
                                                        />
                                                        <p>{payment.status}</p>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton />
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </form>
                )}
            />

            {showLogs ? (
                <Grid container>
                    <Title size={'m'} value={'Booking Logs'} />
                    {bookingLogs ? (
                        <>
                            <BasicTable
                                columns={
                                    !isSmall
                                        ? bookingLogsOptions.columns
                                        : bookingLogsOptions.mobileColumns ||
                                          bookingLogsOptions.columns
                                }
                                values={bookingLogs}
                            />
                            <br />
                            <Grid container alignItems="flex-end">
                                <Grid item xs={1} sm={7}></Grid>
                                <Grid item xs={11} sm={5}>
                                    {bookingLogsOptions.total /
                                        bookingLogsOptions.MAX_PAGE <
                                    1 ? null : (
                                        <PaginationControlled
                                            count={Math.ceil(
                                                bookingLogsOptions.total /
                                                    bookingLogsOptions.MAX_PAGE
                                            )}
                                            page={bookingLogsOptions.page}
                                            onChange={
                                                bookingLogsOptions.handlePageChange
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            ) : (
                ''
            )}

            <Dialog
                open={openAvailability}
                hideTitle={true}
                hideActions={true}
                handleClose={handleCloseSetDate}
                dialogContent={
                    <ExperienceAvailabilityPageComponent
                        showLoading={loading}
                        showTitle={false}
                        showQuantity={false}
                        // onChange={(date) => {
                        //     handleDatePickerChange(date);
                        // }}
                        // onMonthChange={(date) => {
                        //     handleMonthChange(date);
                        // }}
                        setDatePicker={setDatePicker}
                        datePicker={datePicker} // Cambiar
                        slots={slots} // slots
                        showPrice={false}
                        showAvailability={true}
                        resourceSelected={resourceSelected}
                        setResourceSelected={setResourceSelected}
                        resources={resources}
                        experience={selectedExperienceItem}
                        // setTimeOfArrival={setTimeOfArrival} // setSlot
                        setSelectedSlot={(item) => {
                            setTimeOfArrival(item.startUTC, item.timezone);
                        }}
                        setVisibleRange={setVisibleRange}
                        // addBookedExperienceWithQuantityPrice={(experience, price, quanity) => {
                        //     // addBookedExperienceWithQuantityPrice(val);
                        //     // addCheckoutItem(experience, price, quanity)
                        //     setOpenAvailability(false)
                        // }}
                        // onSlotSelect={(slot) => {
                        //     // setSelectedSlotPrice(parseFloat(slot.price));
                        // }}
                    />
                }
            />
            <Dialog
                open={openCancelReason}
                hideTitle={true}
                handleClose={handleCloseCancelReason}
                dialogContent={renderCancelReason()}
                hideBack={true}
                labelNext="OK"
                handleSubmit={handleCloseCancelReason}
            />
        </>
    );
};

export default BookingPageComponent;

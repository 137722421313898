import { isEmpty } from 'lodash';
import { fetch, Method } from 'core/api/fetch';
import { parseScheduleToModel } from '../parser/schedule';
import { parseScheduleToObject, parseScheduleMonthToModel } from 'core/api/parser/schedule';

let expNum = window.location.href.split('/');
let id = parseFloat(expNum[expNum.length - 2]);
let subExpId = parseFloat(expNum[expNum.length - 1]);

const scheduleRoutes = {
  getAll: (id, subExpId) =>
    `/api/v1.1.0/experience/${id ? id : ''}/expDetail/${subExpId ? subExpId : ''
    }/schedule`,
  getByScheduleId: (expId, expDetailId, scheduleId, groupSize, date) =>
    `api/v1.1.0/experience/${expId}/expDetail/${expDetailId}/schedule/${scheduleId}?group_size=${groupSize ?? 1
    }&day=${date}`,
  create: (id, subExpId) =>
    `/api/v1.1.0/experience/${id ? id : ''}/expDetail/${subExpId ? subExpId : ''
    }/schedule`,
  deleteById: (id, subExpId, scheduleId, day, all) =>
    `/api/v1.1.0/experience/${id}/expDetail/${subExpId}/schedule/${scheduleId}?all=${all}${all ? '' : '&day=' + day
    }`,
  updateById: (id, subExpId, scheduleId, all) =>
    `/api/v1.1.0/experience/${id}/expDetail/${subExpId}/schedule/${scheduleId}?all=${all}`,
  getByDateMonth: (expId, expDetailId, date, mod, groupSize, code) => `api/v1.1.0/experience/${expId}/expDetail/${expDetailId}/availabilty?day=${date}${mod ? `&mod=${mod}` : ''}&group_size=${groupSize ?? 1}${code ? `&code=${code}` : ''}`,
  getAvailability: ({ expId, fromDateTime, toDateTime, groupSize, code, resources }) =>
    `api/v1.1.1/experience/${expId}/availabilty?fromDateTime=${fromDateTime.toISOString()}&toDateTime=${toDateTime.toISOString()}&group_size=${
        groupSize ?? 1
    }${code ? `&code=${code}` : ''}${
        resources
            ? `&resources=${encodeURIComponent(JSON.stringify(resources))}`
            : ''
    }`,
};

export const ScheduleService = {
  // get: async (day, mod) => {
  get: async () => {
    try {
      const { data } = await fetch(scheduleRoutes.getAll(id, subExpId), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return isEmpty(data) ? [] : data.map(parseScheduleToModel);
    } catch (err) {
      throw new Error(err);
    }
  },

  create: async (info) => {
    try {
      const { data } = await fetch(scheduleRoutes.create(id, subExpId), {
        method: Method.POST,
        authenticated: true,
        data: parseScheduleToObject(info),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // console.log('create data', data);

      return isEmpty(data) ? {} : data;
    } catch (err) {
      throw new Error(err);
    }
  },

  getUpdated: async (expId, subExpId, date, calendarMod) => {
    try {
      const { data } = await fetch(
        scheduleRoutes.getAll(expId, subExpId) +
        `?anticipation=false&day=${date}${calendarMod ? `&mod=${calendarMod}` : ''
        }`,
        {
          method: Method.GET,
          authenticated: true,
        }
      );
      return isEmpty(data) ? [] : data.map(parseScheduleToModel);
    } catch (err) {
      throw new Error(err);
    }
  },

  createUpdated: async (expId, subExpId, values) => {
    try {
      const { data } = await fetch(scheduleRoutes.create(expId, subExpId), {
        method: Method.POST,
        authenticated: true,
        data: parseScheduleToObject(values),
      });
      // console.log('create data', data);

      return isEmpty(data) ? {} : data;
    } catch (err) {
      throw new Error(err);
    }
  },

  delete: async (expId, subExpId, id, day, all) => {
    try {
      console.log('delete', expId, subExpId, id, day, all);
      const { data } = await fetch(
        scheduleRoutes.deleteById(expId, subExpId, id, day, all),
        {
          method: Method.DELETE,
          authenticated: true,
        }
      );

      if (data[0] != true) {
        throw new Error('Could not delete schedule');
      }

      return true;
    } catch (err) {
      throw new Error(err);
    }
  },

  update: async (expId, subExpId, id, values, all) => {
    try {
      console.log('update', expId, subExpId, id, values, all);
      const { data } = await fetch(
        scheduleRoutes.updateById(expId, subExpId, id, all),
        {
          method: Method.PATCH,
          authenticated: true,
          data: parseScheduleToObject(values),
        }
      );
      return isEmpty(data) ? {} : data;
    } catch (err) {
      throw new Error(err);
    }
  },

  getByScheduleId: async (expId, expDetailId, scheduleId, groupSize, date) => {
    try {
      let { data } = await fetch(
        scheduleRoutes.getByScheduleId(
          expId,
          expDetailId,
          scheduleId,
          groupSize,
          date
        ),
        {
          method: Method.GET,
          authenticated: true,
        }
      );
      return isEmpty(data) ? [] : parseScheduleToModel(data[0]);
    } catch (err) {
      throw new Error(err);
    }
  },

  getByDateMonth: async (expId, expDetailId, date, mod, groupSize, code) => {
    try {
      const { data } = await fetch(scheduleRoutes.getByDateMonth(expId, expDetailId, date, mod, groupSize, code), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return isEmpty(data) ? [] : data.map(parseScheduleMonthToModel);
    } catch (err) {
      throw new Error(err);
    }
  },

  getAvailability: async ({
    expId,
    fromDateTime,
    toDateTime,
    groupSize,
    code,
    resources,
}) => {
    try {
        const { data } = await fetch(
            scheduleRoutes.getAvailability({
                expId,
                fromDateTime,
                toDateTime,
                groupSize,
                code,
                resources
            }),
            {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return isEmpty(data) ? [] : data.map(parseScheduleMonthToModel);
    } catch (err) {
        throw new Error(err);
    }
},
};

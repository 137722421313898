export const ROUTES = {
  LANDING: '/',
  HOME: '/home',
  HOST: '/host',
  BOOKINGS: '/booking',
  BOOKINGS_ID: '/booking/:id',
  BOOKINGS_ADD: '/booking/add',
  BOOKINGS_NEW: '/booking/new',
  BOOKINGS_NEW_ID: '/booking/new/:id',
  BOOKINGS_NEW_CODE: '/booking/new/:id/code/:code',
  CHECKOUT_VALIDATION: '/checkout/validation',
  FORGOTTEN_PASSWORD: {
    SEND: '/reset-password',
    VERIFY: '/reset-password/verify/:code',
    RESET: '/reset-password/reset',
  },

  // LANDINGHOSTEL: '/hostel',
  // BOOKING: '/booking/:id',
  // BOOKING_CODE: '/booking/:id/code/:code',
  // CHECKOUT: '/checkout',
  // CHECKOUT_VALIDATION: '/checkout/validation',
  // SAVEDEXPERIENCES: '/saved-experiences',
  // QR: '/qr',
  // PURCHASE: '/purchase',
  // ERROR: '/error',
  // PENDING: '/pending'
};
